export const services = [
   {
      id: 1,
      textIcon: 'J',
      textIconBackgroundColor: '#219F94',
      title: 'PDF To JPG',
      description: 'Easily convert PDF to JPG',
   },
   {
      id: 2,
      textIcon: 'W',
      textIconBackgroundColor: 'black',
      title: 'PDF To Word',
      description: 'Easily convert PDF to word',
   },
   {
      id: 3,
      textIcon: 'P',
      textIconBackgroundColor: 'red',
      title: 'PDF To Powerpoint',
      description: 'Easily convert PDF to Powerpoint',
   },
   {
      id: 4,
      textIcon: 'P',
      textIconBackgroundColor: 'darkblue',
      title: 'JPG To PDF',
      description: 'Easily convert JPG to PDF',
   },
   {
      id: 5,
      textIcon: 'P',
      textIconBackgroundColor: 'purple',
      title: 'Word To PDF',
      description: 'Easily convert Word to PDF',
   },
   {
      id: 6,
      textIcon: 'P',
      textIconBackgroundColor: '#325288',
      title: 'Powerpoint to PDF',
      description: 'Easily convert Powerpoint to PDF',
   },
   {
      id: 7,
      textIcon: 'P',
      textIconBackgroundColor: '#000B49',
      title: 'Excel to PDF',
      description: 'Easily convert Excel to PDF',
   },
   {
      id: 8,
      textIcon: 'P',
      textIconBackgroundColor: '#0B4619',
      title: 'HTML to PDF',
      description: 'Easily convert HTML to PDF',
   },
   {
      id: 9,
      textIcon: 'M',
      textIconBackgroundColor: '#FF5C58',
      title: 'MP4 to MP3',
      description: 'Easily convert MP4 to MP3',
   },
];

export const fileTypes = [
   {
      id: 1,
      title: 'PDF',
      fileExtension: 'pdf',
      possibleConversionFileTypes: 'JPG,WORD,POWERPOINT',
   },
   {
      id: 2,
      title: 'JPG',
      fileExtension: 'jpg',
      possibleConversionFileTypes: 'PDF',
   },
   {
      id: 3,
      title: 'WORD',
      fileExtension: 'docx',
      possibleConversionFileTypes: 'PDF',
   },
   {
      id: 4,
      title: 'POWERPOINT',
      fileExtension: 'ppt',
      possibleConversionFileTypes: 'PDF',
   },
   {
      id: 5,
      title: 'EXCEL',
      fileExtension: 'xlsx',
      possibleConversionFileTypes: 'PDF',
   },
   {
      id: 6,
      title: 'HTML',
      fileExtension: 'html',
      possibleConversionFileTypes: 'PDF',
   },
   {
      id: 7,
      title: 'MP4',
      fileExtension: 'mp4',
      possibleConversionFileTypes: 'MP3',
   },
];
